import { Component, OnInit, ElementRef, AfterViewInit } from '@angular/core';
import { fromEvent } from 'rxjs/observable/fromEvent';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { Options } from 'ng5-slider';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { saveAs } from 'file-saver';
@Component({
  selector: 'app-structure',
  templateUrl: './structure.component.html',
  styleUrls: ['./structure.component.scss']
})
export class StructureComponent implements OnInit, AfterViewInit {
  // Declarations
  pathSelect = document.getElementsByClassName('ng5-slider-model-value');
  colorVal = document.getElementsByClassName('sketch-active');
  eraserVal = document.getElementsByClassName('eraser');
  downloadBtn = document.getElementById('downloadLnk');
  tooltype = 'draw';
  private canvas: HTMLCanvasElement = null;
  private ctx: CanvasRenderingContext2D;
  options: Options = { floor: 1, ceil: 30 };
  value = 1;
  isPress = false;
  old = null;
  public show = false;
  rangeShow = false;
  infiniteX = 0;
  infiniteY = 0;
  faBars = faBars;
  points = [];

  // Initializations
  initialize(mountPoint: HTMLElement) {
    this.canvas = mountPoint.querySelector('canvas');
    this.ctx = this.canvas.getContext('2d');
    this.canvas.width = this.canvas.offsetWidth;
    this.canvas.height = this.canvas.offsetHeight;
    this.ctx.lineJoin = 'round';
    this.ctx.lineCap = 'round';
  }

  constructor(private elRef: ElementRef) {}

  ngOnInit() {
    this.initialize(this.elRef.nativeElement);
    this.startPainting();
  }

  paint({ clientX, clientY }) {
    this.ctx.beginPath();
    if (
      Math.abs(this.infiniteX - clientX) < 100 &&
      Math.abs(this.infiniteY - clientY) < 100
    ) {
      this.ctx.moveTo(this.infiniteX, this.infiniteY);
    }
    this.ctx.lineTo(clientX, clientY);
    this.ctx.stroke();
    this.ctx.strokeStyle = `${this.colorVal[0].attributes[2].nodeValue
      .split(':')[1]
      .replace(';', '')
      .trim()}`;
    this.ctx.lineWidth = Number(`${this.pathSelect[0].innerHTML}`);
    this.infiniteX = clientX;
    this.infiniteY = clientY;
  }

  public startPainting() {
    const { nativeElement } = this.elRef;
    const canvas = nativeElement.querySelector('canvas') as HTMLCanvasElement;
    const move$ = fromEvent<MouseEvent>(canvas, 'mousemove');
    const down$ = fromEvent<MouseEvent>(canvas, 'mousedown');
    const up$ = fromEvent<MouseEvent>(canvas, 'mouseup');
    const paints$ = down$.pipe(
      mergeMap( () => move$.pipe( takeUntil(up$) ) )
      );

    // tslint:disable-next-line:no-console
    // down$.subscribe(console.info);

    const offset = getOffset(canvas);
    paints$.subscribe(event => {
      const clientX = event.clientX - offset.left;
      const clientY = event.clientY - offset.top;
      this.paint({ clientX, clientY });
    });
  }

  showMenu() {
    this.show = !this.show;
    if (this.show === true) {
      document.getElementsByClassName('colorBox')[0].classList.add('tow');
    }
    if (this.show === false) {
      document.getElementsByClassName('colorBox')[0].classList.add('win');
      document.getElementsByClassName('colorBox')[0].classList.remove('tow');
    }
  }
  // save button
  saveBtn() {
    const image = document.querySelector('canvas');
    image.toBlob((blob) => {
      saveAs(blob, 'colorful-image.png');
    });
  }

  ngAfterViewInit() {}

  range() {
    this.rangeShow = !this.rangeShow;
  }

  clearScreen() {
    const context = this.canvas.getContext('2d');
    context.clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  // undo
  undoDraw() {
    const lastDraw = this.points.pop();
    // redoStack.unshift(lastDraw);
    this.reDrawAll();
  }
  reDrawAll() {
    if (fromEvent<MouseEvent>(this.canvas, 'mousedown')) {
      this.points.push({
        size: this.ctx.lineWidth,
        color: this.ctx.strokeStyle
      });
    }
    if (this.points.length === 0) {
      return;
    }
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < this.points.length; i++) {
      const element = this.points[i];
      const begin = false;
      if (this.ctx.lineWidth !== element.size) {

      }
    }
  }
  // PenTool
  PenTool = tool => {
    this.canvas.classList.add('penTool');
    this.canvas.classList.remove('apo');
    this.tooltype = tool;
    this.canvas.addEventListener('mousedown', e => {
      this.isPress = true;
      this.old = { x: e.offsetX, y: e.offsetY };
    });
    this.canvas.addEventListener('mousemove', e => {
      if (fromEvent<MouseEvent>(this.canvas, 'mousedown')) {
        this.ctx.beginPath();
        if (this.tooltype === 'draw') {
          this.ctx.globalCompositeOperation = 'source-over';
          this.ctx.lineWidth = Number(`${this.pathSelect[0].innerHTML}`);
        }
      }
    });
    this.canvas.addEventListener('mouseup', e => {
      this.isPress = false;
    });
  }

  // Eraser
  changeCursor = tool => {
    this.canvas.classList.add('apo');
    this.canvas.classList.remove('penTool');
    this.tooltype = tool;
    this.canvas.addEventListener('mousedown', e => {
      this.isPress = true;
      this.old = { x: e.offsetX, y: e.offsetY };
    });
    this.canvas.addEventListener('mousemove', () => {
      if (fromEvent<MouseEvent>(this.canvas, 'mousedown')) {
        this.ctx.beginPath();
        if (this.tooltype === 'erase') {
          this.ctx.globalCompositeOperation = 'destination-out';
          this.ctx.lineWidth = Number(50);
        }
      }
    });
    this.canvas.addEventListener('mouseup', () => {
      this.isPress = false;
    });
  }
}

function getOffset(el: HTMLElement) {
  const rect = el.getBoundingClientRect();

  return {
    top: rect.top + document.body.scrollTop,
    left: rect.left + document.body.scrollLeft
  };
}
